<script>
import AppTitle from "@/layout/components/AppTitle.vue";
import User from "@/layout/components/User.vue";
export default {
  name: "index",
  components: { User, AppTitle}
}
</script>

<template>
  <el-container class="layout">
    <el-header height="64px" class="layout-header">
      <AppTitle />
      <User />
    </el-header>
    <el-main class="layout-main">
      <transition name="router-fade" mode="out-in">
        <router-view v-if="!$route.meta.keepAlive"/>
      </transition>
    </el-main>
  </el-container>
</template>

<style scoped lang="less">
.layout {
  height: 100%;
  overflow: hidden;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    background-color: #ffffff;
  }

  &-main {
    margin: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    overflow: auto;
  }
}
</style>
