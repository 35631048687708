<script>
export default {
name: "AppTitle"
}
</script>

<template>
  <div class="title">
    <img src="@/assets/image/logo.png"  alt="logo" style="width: 25px; height: 25px"/>
    <span>审批中心</span>
  </div>
</template>

<style scoped lang="less">
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  span {
    font-size: 18px;
    font-weight: bolder;
  }
}
</style>
