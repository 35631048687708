<script>
import { getUserInfo, logout } from "@/api/login";

export default {
  name: "User",
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    if (!this.$store.state.user.userId) this.getUserInfo()
  },
  computed: {
    avatar() {
      return this.$store.state.user.avatar
    },
    username() {
      return this.$store.state.user.username
    }
  },
  methods: {
    getUserInfo() {
      getUserInfo().then(res => {
        if (res.data.code === 200) {
          this.$store.dispatch('setUser', { username: res.data.data.name, ...res.data.data })
        }
      })
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout()
      }
    },
    logout() {
      logout().then(() => {
        sessionStorage.clear()
        localStorage.clear()
        this.$store.dispatch('setUser', {})
        this.$router.push('/login')
      })
    }
  }
}
</script>

<template>
  <div class="app-user">
   <span>
     <span>欢迎您，</span>
     <span>{{ username }}</span>
   </span>
    <el-dropdown @command="handleCommand">
      <el-avatar :src="avatar"/>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<style scoped lang="less">
.app-user {
  display: flex;
  align-items: center;
  gap: 12px;
}
</style>
